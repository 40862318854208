<template>
  <div class="content">
    <div class="data_item">
      <div class="img"></div>
      <div class="info">
        <div class="title">{{detail.gsname}}({{detail.bsite}}) <i class="vip"></i></div>
<!--        <div class="icons">-->
<!--          <div class="certification"></div>-->
<!--          <div class="receive icon"></div>-->
<!--          <div class="send icon"></div>-->
<!--          <div class="instead icon"></div>-->
<!--          <div class="security icon"></div>-->
<!--          <div>5806浏览 · 1008收藏</div>-->
<!--        </div>-->
        <div class="address">
          <el-link type="primary">{{detail.bsheng}} {{detail.bcity}}</el-link>
          →
          <el-link type="primary">{{detail.esheng}} {{detail.ecity}}</el-link>
        </div>
<!--        <div class="info_txt">-->
<!--          简介：广州市XXX物流有限公司是一家专业从事长途、短途货物运输...-->
<!--        </div>-->
        <div class="info_txt">地址：{{detail.baddr}}</div>
      </div>
      <div class="right_info">
        <div>重货：<span class="orange">{{detail.w_price}}</span>元/吨</div>
        <div>泡货：<span class="red">{{detail.v_price}}</span>元/方</div>
        <div>时效：{{detail.timeshift}}</div>
      </div>
    </div>
    <div class="right_box">
<!--      <div-->
<!--        class="collection pointer op-7"-->
<!--        @click="isCollection = !isCollection"-->
<!--      >-->
<!--        <div class="heart" :class="{ heart_red: isCollection }"></div>-->
<!--        已收藏-->
<!--      </div>-->
      <div class="btn">
        <el-button type="danger"
                   icon="el-icon-s-promotion"
                   size="mini"
                   @click="goDeliver">去发货</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: ()=> ({})
    }
  },
  data() {
    return {
      isCollection: false,
    };
  },
  methods:{
    goDeliver(){
      this.$message({
        message: '暂无此功能，敬请期待！',
        type: 'warning'
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: space-between;
  .right_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 100px;
    .collection {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: 14px;
      color: #333333;

      .heart {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("../../../assets/data_item/heart.png");
        background-size: 100% 100%;
        margin-right: 5px;
      }
      .heart_red {
        background: url("../../../assets/data_item/heart_red.png");
        background-size: 100% 100%;
      }
    }
  }
}
.data_item {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  .img {
    width: 180px;
    height: 136px;
    background: url("../../../assets/data_item/storefront.png");
    background-size: 100% 100%;
    margin-right: 10px;
  }
  .info {
    width: 365px;
    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #fa6400;
      font-weight: 500;
      margin-bottom: 10px;
      .vip {
        background: url("../../../assets/data_item/vip.png");
        background-size: 100% 100%;
        margin-left: 5px;
        display: inline-block;
        width: 60px;
        height: 24px;
        // border: 1px solid red;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #a0a0a0;
      margin-bottom: 10px;
      .certification {
        background: url("../../../assets/data_item/certification.png");
        background-size: 100%;
        width: 44px;
        height: 14px;
        margin-right: 5px;
      }
      .icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      .receive {
        background: url("../../../assets/data_item/receive.png");
        background-size: 100%;
      }
      .send {
        background: url("../../../assets/data_item/send.png");
        background-size: 100%;
      }
      .instead {
        background: url("../../../assets/data_item/instead.png");
        background-size: 100%;
      }
      .security {
        background: url("../../../assets/data_item/security.png");
        background-size: 100%;
      }
    }
    .address {
      margin-bottom: 10px;
    }
    .info_txt {
      font-size: 12px;
      color: #a0a0a0;
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .right_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    color: #a0a0a0;
    margin-left: 60px;
    div {
      margin-bottom: 5px;
    }
    .orange {
      color: #ff8200;
      font-weight: 700;
    }
    .red {
      color: #e93131;
      font-weight: 700;
    }
  }
}
</style>
