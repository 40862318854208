import request from "@/utils/request";

/**
 * http://n9.cu56.com:8080/FileUpLoadApp/SqlDataGet.ashx
 * 获取专线数据
 * @param data
 * @returns {*}
 */
export const getSpecialLine = data => {
    return request({
        url: '/FileUpLoadApp/SqlDataGet.ashx?pars='+data,
        method: 'post',
        // data
    })
}
