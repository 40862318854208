<template>
  <div class="content">
    <div style="padding: 0 70px">
      <div class="banner">
        <banner></banner>
      </div>
    </div>

    <div class="search_box">
      <!--            <el-card class="box-card mb20" >-->
      <!-- 搜索模块 -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" label-position="left"
        class="demo-ruleForm">
        <!-- <el-row type="flex" justify="start" align="middle">
          <el-col :span="8">
            <el-form-item label-width="0">
              <el-radio-group v-model="ruleForm.type">
                <el-radio label="">全部</el-radio>
                <el-radio label="0">直达线路</el-radio>
                <el-radio label="1">中转线路</el-radio>
              </el-radio-group>
              <div class="mb20"></div>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row type="flex" justify="start" :gutter="20">
          <el-col class="flexSC" :span="6">
            <el-form-item label="发站：" prop="start">
              <el-cascader size="medium" v-model="ruleForm.start" :options="options" :props="cascaderProps"
                id="ruleFormstart" style="width: 250px!important;"></el-cascader>
            </el-form-item>
          </el-col>
          <div class="box flex-c pointer op-7" @click="exchange">
            <div class="separator">换</div>
          </div>
          <el-col class="flexSC" :span="6">
            <div class="box">
              <el-form-item label="到站：" prop="end">
                <el-cascader size="medium" v-model="ruleForm.end" :options="options" :props="cascaderProps"
                  style="width: 250px!important;"></el-cascader>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="7">
            <el-form-item label="">
              <el-input placeholder="请输入关键字" v-model="ruleForm.keyword"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button icon="el-icon-search" type="primary" @click="submit">立即搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- 历史搜索 -->
      <div class="history_search" v-if="specialLine.length > 0">
        <el-row>
          <el-col :span="20">
            <div class="flexSC wrap">
              <span class="title">历史搜索：</span>
              <div class="history" v-for="(item, index) in specialLine" :key="index">
                <el-tag class="pointer op-7" type="primary" closable @click="getSearchForm(item)" @close="closeTag(index)"
                  size="small">
                  {{ item.start[0] }} {{ item.start[1] }} → {{ item.end[0] }} {{ item.end[2] }}
                </el-tag>
              </div>
              <div>
                <el-tag class="clean_btn op-7 pointer" type="warning" @click="delSearchForm">清除</el-tag>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 查询统计 -->
      <div class="queries_num">
        <div class="flexSC">
          <img class="logobg" src="../../assets/logo2.png" alt="" />
          <div class="count">
            累计
            <count-to :startVal="0" :endVal="zxcount" :duration="1000"></count-to>
            次查询
          </div>
        </div>
        <div class="right_img">
          <img src="../../assets/magnifying_glass.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 查询结果 -->
    <div class="data_box">
      <div class="title">
        共为您找到 <span>{{ gscount }}</span> 家物流公司，<span>{{ dataList.length }}</span> 条线路~
      </div>
      <div class="data_title flexSC">
        <div class="bar"></div>
        <div class="txt">专线推荐</div>
      </div>
      <template v-if="dataList.length > 0">
        <!-- <transition-group appear tag="el-col" @beforeEnter="beforeEnter" @enter="enter">
          <div class="list" v-for="(item, index) in dataList" :key="index">
            <data-item :detail="item"></data-item>
          </div>
        </transition-group> -->
        <div class="list">
          <el-table :data="dataList" border stripe size="medium" style="width: 100%">
            <el-table-column prop="gsname" label="专线名称"></el-table-column>
            <el-table-column prop="bsite" label="发货网点名称"></el-table-column>
            <el-table-column prop="fetchaddr" label="发货网点地址"></el-table-column>
            <el-table-column prop="man" label="发货网点主管"></el-table-column>
            <el-table-column prop="fetchtel" label="发货网点电话"></el-table-column>
            <el-table-column prop="bsite1" label="到站网点名称"></el-table-column>
            <el-table-column prop="fetchaddr1" label="到站网点地址"></el-table-column>
            <el-table-column prop="man1" label="到站网点主管"></el-table-column>
            <el-table-column prop="fetchtel1" label="到站网点电话"></el-table-column>
          </el-table>
        </div>
      </template>
      <template v-else>
        <el-empty :image-size="100" description="暂无数据"></el-empty>
      </template>
      <div class="mb20">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
          :page-sizes="[100, 200, 300, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";
import dataItem from "./components/data-item.vue";
import { getSpecialLine } from '@/api/special-line'
import region from '@/global/region.json'
import { mapGetters, mapActions } from 'vuex'
import Banner from '@/components/Banner'
import AMap from 'AMap'
let ind = 0
export default {
  metaInfo: {
    title: '中国物流网,物流,货源,车源,专线',
    meta: [
      {
        name: 'keywords',
        content: '中国物流网,物流,货源,车源,专线'
      },
      {
        name: 'description',
        content: '中国物流网,物流,货源,车源,专线'
      }
    ]
  },
  components: {
    countTo,
    dataItem,
    Banner,
    AMap
  },
  data() {
    return {
      imgs: [
        // require('@/assets/banner1.png'),
        // require('@/assets/banner.png')
      ],
      int: 0,
      page: 1,
      pageSize: 100,
      total: 0,
      options: region,
      gscount: 0, // 公司数量
      zxcount: 0, // 查询次数
      cascaderProps: {
        expandTrigger: 'hover',
        // value: 'code',
        value: 'name',
        label: 'name',
        children: 'children'
      },
      ruleForm: {
        type: '',
        start: [],
        end: ['全部', '全部', '全部'],
        keyword: this.$route.query.input || '',
      },
      dataList: [],
      rules: {
        start: [
          { required: true, message: '请输入发起地点', trigger: 'change' }
        ],
        end: [
          { required: true, message: '请输入接收地点', trigger: 'change' }
        ],
      },
    };
  },
  watch: {
    $route(newVal) {
      this.ruleForm.keyword = newVal.query.input
      this.submit()
    },
  },
  computed: {
    ...mapGetters([
      'specialLine'
    ])
  },
  mounted() {
    this.getSearchCount()
    this.getlocal();
  },
  methods: {
    ...mapActions('searchHistory', [
      'setSpecialLine',
      'remoteSpecialLine'
    ]),
    beforeEnter(el) {
      //el.style.opacity = 0
      el.style.paddingTop = '100px'
    },
    // 排队按顺序进场动画
    enter(el, done) {
      console.log('enter => ', el.dataset.index)
      let delay = ind * 100
      ind += 1
      setTimeout(() => {
        //el.style.opacity = 1
        // el.style.transition = 'opacity 1s '
        el.style.animation = 'bounceInUp 1s'
        // el.style.animation = 'one-in 4s infinite'
        //el.style['animation-iteration-count'] = 1
        el.style.paddingTop = '0px'
        done()
      }, delay)
    },
    // 删除指定搜索历史
    closeTag(index) {
      this.remoteSpecialLine(index)
    },
    // 出发站和到站调换
    exchange() {
      let arr = []
      arr = [...this.ruleForm.start]
      this.ruleForm.start = this.ruleForm.end
      this.ruleForm.end = arr
    },
    // 获取查询次数
    getSearchCount() {
      let params = {
        method: "Query",
        proc: "GET_APP_ZXCOUNT",
        pars: [
          {}
        ],
        tmscode: "89569",
        loginsite: "全部",
        token: ""
      }
      this.$api(params, { loading: false }).then(res => {
        this.zxcount = res.msg.length && res.msg[0].out_zxcount
      })
    },
    // 搜索历史填入输入框，并执行搜索
    getSearchForm(item) {
      this.ruleForm = { ...item }
      this.getData()
    },
    // 清除搜索历史
    delSearchForm() {
      this.remoteSpecialLine()
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 设置历史搜索
          this.setSpecialLine({ ...this.ruleForm })
          this.getData()
        }
      })
    },
    // 获取数据
    getData() {
      let { start, end } = this.ruleForm
      let subForm = {}
      // subForm.type = this.ruleForm.type
      subForm.keyword = this.ruleForm.keyword
      // subForm.pageSize = this.pageSize
      // subForm.page = this.page
      subForm.bsheng = start.length && start[0]
      subForm.bcity = start.length && start[1]
      subForm.barea = start.length && start[2]
      subForm.esheng = end.length && end[0]
      subForm.ecity = end.length && end[1]
      subForm.earea = end.length && end[2]
      let params = {
        method: "Query",
        proc: "Get_app_zxinfo_new",
        pars: [
          { ...subForm }
        ],
        tmscode: "89569",
        loginsite: "全部",
        token: ""
      }
      getSpecialLine(JSON.stringify(params)).then(res => {
        var b = subForm
        var a = res;
        this.dataList = [...res.msg]
        this.gscount = res.msg.length && res.msg[0].gscount
        this.total = res.msg.length && res.msg[0].count
        this.zxcount++ // 手动添加一次查询次数，不需要通过请求接口
      })
    },
    handleCurrentChange(e) {
      this.page = e
      this.getData()
    },
    handleSizeChange(e) {
      this.pageSize = e
      this.getData()
    },
    getlocal() {
      let that = this;
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 3000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: 'RB'
        })

        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete)
        AMap.event.addListener(geolocation, 'error', onError)

        function onComplete(data) {
          console.log("详细更新")
          that.ruleForm.start = [data.addressComponent.province, data.addressComponent.city, data.addressComponent.district]
        }

        function onError(data) {
          AMap.plugin('AMap.CitySearch', function () {
            var citySearch = new AMap.CitySearch()
            citySearch.getLocalCity(function (status, result) {
              if (status === 'complete' && result.info === 'OK') {
                console.log("简单更新")
                that.ruleForm.start = [result.province, result.city, ""]
                // document.getElementsByClassName("el-input__inner")[0].value = result.province + " / " + result.city;
                document.getElementsByClassName("el-input__inner")[0].value = that.ruleForm.start.join(" / ")
              }
            })
          })
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.mb20 {
  margin-bottom: 20px;
  text-align: right;
}

.wrap {
  flex-wrap: wrap;
}

.pl25 {
  padding-left: 25px;
}

.flexSC {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.content {
  .el-cascader {
    width: 100% !important;
  }

  .banner {
    width: 100%;
    height: 230px;
    margin-bottom: 20px;
    background: url("../../assets/banner1.png");
    background-size: 100% 100%;
  }

  .search_box {
    padding: 0 70px;
    margin-bottom: 20px;

    .box {
      display: flex;
      align-items: center;

      .separator {
        width: 20px;
        height: 20px;
        color: #f5a623;
        border: 1px solid #f5a623;
        border-radius: 50%;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;

        &:hover {
          animation: heartBeat 1s;
          animation-iteration-count: 1;
        }
      }
    }

    .history_search {
      margin: 20px 0;

      .title {
        //margin-left: 25px;
        font-size: 14px;
        color: #999999;
      }

      .history {
        margin-right: 10px;
        font-size: 14px;
        line-height: 30px;
      }

      .pointer {
        animation: bounceIn 1s;

        &:hover {
          animation: jello 1s;
          animation-iteration-count: 1;
        }
      }

      .clean_btn {
        color: red !important;
      }
    }

    .queries_num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .logobg {
        width: 120px;
        height: 40px;
        margin-right: 25px;
        opacity: 0.7;
      }

      .count {
        color: #999999;
      }

      .right_img {
        width: 133px;
        height: 60px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .data_box {
    padding: 0 70px;
    overflow: hidden;

    .title {
      font-size: 14px;
      color: #333333;
      margin-bottom: 40px;

      span {
        color: #eb5e00;
      }
    }

    .data_title {
      margin-bottom: 20px;

      .bar {
        width: 4px;
        height: 18px;
        background: #eb5e00;
        border-radius: 2px;
        margin-right: 30px;
      }

      .txt {
        font-size: 18px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 700;
      }
    }

    .list {
      margin-bottom: 50px;

      .text {
        font-size: 16px;
        color: #FA6400;
        font-weight: 600;
      }
    }
  }
}

.el-form-item--small.el-form-item {
  margin-bottom: 0 !important;
}
</style>
